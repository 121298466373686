
import { FaEnvelope, FaDownload, FaHeart, FaRegHeart, FaQrcode, FaExternalLinkAlt } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FaEdit, FaTrash, FaUser } from 'react-icons/fa';
import { FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import { fetchPropertyQRCode, getFolders } from '../utils/auth';
import placeholderImage from './placeholder.webp';
import { Link } from 'react-router-dom';
import { usePropertySavedState } from './hooks/usePropertySavedState';
import { Heart } from 'lucide-react';


const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  color: #fff;
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;
const BlurredContent = styled.div`
  filter: blur(4px);
  user-select: none;
  cursor: default;
  pointer-events: none;
`;
const StyledCard = styled(Card)`
  .card-img-top {
    height: 250px;
    object-fit: cover;
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .card-text {
    margin-bottom: 1rem;
  }
  .property-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    span {
      word-wrap: break-word;
      max-width: 50%;
      text-align: left;
    }
  }
  .property-description {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .address-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .address-description div {
    flex: 1 1 45%;
    word-wrap: break-word;
    text-align: left;
  }
  .address-description a {
    flex: 0 0 auto;
    text-align: right;
    white-space: nowrap;
    margin-left: auto;
  }
  .verified-icon {
    color: green;
    margin-left: 5px;
  }
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 1rem;

  button {
    padding: 0.5rem;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
    
    &.primary {
      background-color: var(--color-blue-main);
      color: white;
      
      &:hover {
        opacity: 0.9;
      }
    }
    
    &.secondary {
      background-color: #EDF2F7;
      color: #4A5568;
      
      &:hover {
        background-color: #E2E8F0;
      }
    }
  }
`;

const TruncatedDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  margin-bottom: 0.5rem;
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: var(--color-blue-main);
  padding: 0;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 1rem;

  &:hover {
    opacity: 0.8;
  }
`;

const AgentInfo = styled(Card.Text)`
  border-top: 1px solid #eee;
  padding-top: 12px;
  margin-top: 12px;
  margin-bottom: 0; 
  display: flex;
  justify-content: space-between;
  align-items: center;

  .agent-name-section {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .agent-details {
    text-align: right;
    color: #666;
    font-size: 0.9rem;
  }
`;

const BlurredAmount = styled.span`
  filter: blur(5px);
  user-select: none;
`;


const HeartButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 2;

  &:hover {
    background-color: rgba(255, 255, 255, 0.95);
    transform: scale(1.1);
  }

  &:focus, &:active {
    background-color: white;
    box-shadow: none;
  }

  svg {
    color: ${props => props.isSaved ? '#dc3545' : '#6c757d'};
    transition: all 0.2s ease;
  }

  &:hover svg {
    color: ${props => props.isSaved ? '#dc3545' : '#495057'};
  }
`;
const PropertyDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 1rem;
  color: #666;
  font-size: 0.95rem;

  .details-wrapper {
    display: flex;
    flex-wrap: ${props => props.hasUrl ? 'wrap' : 'nowrap'};
    gap: ${props => props.hasUrl ? '0px' : '20px'};
    flex: 1;
  }

  .basic-details {
    display: flex;
    align-items: center;
    gap: 20px;
    ${props => props.hasUrl && `
      width: 100%;
      margin-bottom: 8px;
    `}
  }

  .sqft-details {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .detail-item {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      color: var(--color-blue-main);
      font-size: 1.1rem;
    }
  }
`;


const QRCodeModal = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    border: none;
  }
  
  .modal-header {
    border-bottom: none;
    padding: 1.5rem 1.5rem 0.5rem;
  }
  
  .modal-body {
    padding: 1.5rem;
    text-align: center;
  }
  
  .qr-code-image {
    max-width: 200px;
    margin: 0 auto;
  }
`;
const PropertyUrl = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--color-blue-main);
  text-decoration: none;
  font-size: 1.1rem;
  padding: 5px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--color-blue-main);
    color: white;
  }
`;

const PropertyCard = ({
  property,
  viewMode,
  isSubscribed,
  onEmailClick,
  onDocumentDownload,
  onEdit,
  onDelete,
  currentUser,
  isUploadSection = false,
  onFavoriteClick,
  isSaved = false
}) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [error, setError] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const formatCompensation = (property) => {
     if (!isSubscribed) {
    return (
      <BlurredContent>
        {'*******'}
      </BlurredContent>
    );
  }
    const metadata = localStorage.getItem(`property_${property.id}_metadata`);
    const storedData = metadata ? JSON.parse(metadata) : null;

    // Use stored data or fallback to property data
    const selectedOption = storedData?.selectedOption || property.selectedOption;
    const concessionType = storedData?.concessionType || property.concessionType;
    const concession = property.concession; // Always use backend value for amount

    if (selectedOption === 'contact-agent' || !concession) {
      return null; // Return null instead of 'Contact Agent'
    }

    const formattedValue = formatNumber(concession);
    return concessionType === 'percent' ?
      `${formattedValue}%` :
      `$${formattedValue}`;
  };

  useEffect(() => {
    const checkIfPropertyInFolders = async () => {
      if (!currentUser) return;

      try {
        const foldersData = await getFolders();
        const isInAnyFolder = foldersData.some(folder =>
          folder.property_count > 0 &&
          folder.properties?.some(p => p.property_details?.id === property.id)
        );
        setIsFavorite(isInAnyFolder);
      } catch (err) {
        console.error('Error checking folders:', err);
      }
    };

    checkIfPropertyInFolders();
  }, [property.id, currentUser]);

  const handleDownloadError = (error) => {
    if (error.message.includes('location restrictions')) {
      setError('You cannot access this property due to location restrictions.');
    } else {
      setError('Failed to download document. Please try again later.');
    }
  };

  const formatNumber = (num) => {
    return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const renderDescription = (property) => {
    if (!isSubscribed) {
      return (
        <BlurredContent>
          {'******* Subscribe to view details *******'}
        </BlurredContent>
      );
    }
  
    return property.description.length > 100 
      ? `${property.description.substring(0, 100)}...` 
      : property.description;
  };;

  const renderConcession = () => {

    const offerType = property.offer_type || property.selectedOption;
    if (offerType === 'contact-agent') {
      return (
        <div className="property-compensation">
          Contact Agent
        </div>
      );
    }

    const value = property.compensation || property.concession;
    if (!value) return null;

    const type = property.compensation_type || property.concessionType;
    const concessionText = type === 'percent'
      ? `${value}%`
      : `$${formatNumber(value)}`;

    return isSubscribed ? (
      <div className="property-compensation">
        Compensation: {concessionText}
      </div>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Subscribe to view compensation</Tooltip>}
      >
        <div className="property-compensation" style={{ filter: 'blur(4px)' }}>
          Compensation: {concessionText}
        </div>
      </OverlayTrigger>
    );
  };

  const isVerified = property.street_address &&
    property.city &&
    property.state &&
    property.zip_code &&
    property.price &&
    (property.bedrooms !== null && property.bedrooms !== undefined) &&
    (property.bathrooms !== null && property.bathrooms !== undefined) &&
    property.sq_ft &&
    property.description &&
    property.pdf_file &&
    property.image &&
    (property.concession !== null && property.concession !== undefined);

  const handleEmailClick = () => {
    if (!onEmailClick) return;
    onEmailClick(property);
  };

  const handleQRCodeClick = () => {
    setShowQRCode(true);
  };

  const handleFavoriteButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!currentUser) return;
    if (onFavoriteClick) {
      onFavoriteClick(property);
    }
  };

  return (
    <>
      <StyledCard className={viewMode === 'list' ? 'mb-3' : 'h-100'}>
        <Row className={viewMode === 'list' ? 'g-0' : ''}>
          <Col md={viewMode === 'list' ? 4 : 12}>
            <div style={{ position: 'relative' }}>
              <HeartButton
                onClick={onFavoriteClick}
                isSaved={isSaved}
                disabled={!currentUser}
              >
                <Heart
                  size={20}
                  fill={isSaved ? '#dc3545' : 'none'}
                  color={isSaved ? '#dc3545' : '#718096'}
                />
              </HeartButton>
              <Card.Img
                variant="top"
                src={property.image ? property.image : placeholderImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImage;
                }}
              />
            </div>
          </Col>
          <Col md={viewMode === 'list' ? 8 : 12}>
            <Card.Body>
              {/* Price and Compensation */}
              <Card.Title className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  {property.isEmpty ? (
                    <strong>Price not available</strong>
                  ) : (
                    <strong>${formatNumber(property.price)}</strong>
                  )}
                  {isVerified && <MdVerified className="verified-icon ms-2" />}
                </div>
                {property.concession && (
  <OverlayTrigger
    placement="top"
    overlay={(!isSubscribed) ? (
      <Tooltip>Subscribe to view compensation</Tooltip>
    ) : <></>}
  >
    <div>
      {formatCompensation(property)}
    </div>
  </OverlayTrigger>
)}
              </Card.Title>

              {/* Address and Email */}
              <Card.Text className="property-details">
                <span>
                  {property.street_address && `${property.street_address}, `}
                  {property.city && `${property.city}, `}
                  {property.state && `${property.state}, `}
                  {property.zip_code}
                </span>
                <StyledButton variant="outline-primary" size="sm" onClick={handleEmailClick}>
                  <FaEnvelope /> Email
                </StyledButton>
              </Card.Text>

              {/* Property Details */}
              <PropertyDetails hasUrl={!!property.next_property_url}>
                <div className="details-wrapper">
                  <div className="basic-details">
                    {property.bedrooms && (
                      <div className="detail-item">
                        <FaBed />
                        <span>{property.bedrooms} bed</span>
                      </div>
                    )}
                    {property.bathrooms && (
                      <div className="detail-item">
                        <FaBath />
                        <span>{Math.floor(property.bathrooms)} bath</span>
                      </div>
                    )}
                  </div>
                  {property.sq_ft && (
                    <div className="sqft-details">
                      <FaRulerCombined />
                      <span>{formatNumber(property.sq_ft)} sqft</span>
                    </div>
                  )}
                </div>
                {property.next_property_url && (
                  <PropertyUrl
                    href={property.next_property_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExternalLinkAlt size={14} />
                    Property URL
                  </PropertyUrl>
                )}
              </PropertyDetails>

              {property.description && (
  <div className="property-description">
    <OverlayTrigger
      placement="top"
      overlay={!isSubscribed ? <Tooltip>Subscribe to view property details</Tooltip> : <></>}
    >
      <div>
        {renderDescription(property)}
      </div>
    </OverlayTrigger>
  </div>
)}


              {/* Action Buttons */}
              <div className="d-flex justify-content-between mt-3 mb-3">
                <StyledButton
                  variant="outline-primary"
                  size="sm"
                  onClick={handleQRCodeClick}
                >
                  <FaQrcode /> QR Code
                </StyledButton>
                {!property.isEmpty && (
                  <OverlayTrigger
                    placement="top"
                    overlay={(!isSubscribed) ? (
                      <Tooltip>Subscribe to view property documents</Tooltip>
                    ) : <></>}
                  >
                    <span>
                      <StyledButton
                        variant="primary"
                        onClick={() => isSubscribed ? onDocumentDownload(property).catch(handleDownloadError) : null}
                        disabled={!isSubscribed}
                      >
                        <FaDownload /> Documents
                      </StyledButton>
                    </span>
                  </OverlayTrigger>
                )}
              </div>



              {/* Edit/Delete Buttons */}
              {isUploadSection && onEdit && onDelete && !property.isEmpty && (
                <div className="mt-2">
                  <StyledButton variant="outline-primary" size="sm" onClick={() => onEdit(property)}>
                    <FaEdit /> Edit
                  </StyledButton>
                  <StyledButton variant="outline-danger" size="sm" className="ms-2" onClick={() => onDelete(property.id)}>
                    <FaTrash /> Delete
                  </StyledButton>
                </div>
              )}

              {/* Alerts */}
              {property.isEmpty && (
                <Alert variant="info" className="mt-3">
                  This property is not in our database yet. An agent can add details for this property.
                </Alert>
              )}

              {error && (
                <Alert variant="danger" className="mt-2" onClose={() => setError(null)} dismissible>
                  {error}
                </Alert>
              )}
            </Card.Body>
          </Col>
        </Row>
      </StyledCard>

      {/* QR Code Modal */}
      <QRCodeModal
        show={showQRCode}
        onHide={() => setShowQRCode(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Property QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {property.qr_code_url ? (
            <img
              src={property.qr_code_url}
              alt="Property QR Code"
              className="qr-code-image"
            />
          ) : (
            <div>QR Code not available</div>
          )}
          <p className="mt-3 text-muted">
            Scan this QR code to share the property details
          </p>
        </Modal.Body>
      </QRCodeModal>
    </>
  );
};

export default PropertyCard;