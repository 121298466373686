import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Pagination, Button, Modal, Form, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { fetchUploads, deleteProperty, fetchBillingInfo, updateBillingInfo, 
         updateProperty, changePassword } from '../utils/auth';
import PropertyCardWrapper from './PropertyCardWrapper';
import { geocodeByAddress } from 'react-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import useGoogleMapsScript from './useGoogleMapsScript';
import SavedPropertiesList from './SavedPropertiesList';
import SubscriptionPopup from './SubscriptionPopup';

const StyledButton = styled(Button)`
  background-color: transparent;
  border-color: var(--color-blue-main);
  color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    color: white;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    display: flex;
    margin-top: 80px; 
    overflow-y: auto;
  }
`;

const StyledOptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  
  li {
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 8px;
    transition: all 0.2s ease;
    
    &:hover {
      background-color: #f8f9fa;
    }
    
    &.active {
      background-color: var(--color-blue-main);
      color: white;
    }
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  .google-places-autocomplete__dropdown-container {
    z-index: 1050;
  }
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
`;

const ProfileGrid = ({ userProfile, handleUserProfileUpdate}) => {
  const [selectedOption, setSelectedOption] = useState('uploads');
  const [currentPage, setCurrentPage] = useState(1);
  const [uploads, setUploads] = useState([]);
  const [billingInfo, setBillingInfo] = useState(null);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [updatedBillingDetails, setUpdatedBillingDetails] = useState('');
  const [showEditPropertyModal, setShowEditPropertyModal] = useState(false);
  const [editingProperty, setEditingProperty] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordChangeMessage, setPasswordChangeMessage] = useState(null);
  const [error, setError] = useState(null);
  const [editingCompensationType, setEditingCompensationType] = useState('dollar');
  const [editingCompensationAmount, setEditingCompensationAmount] = useState('');
  const uploadsPerPage = 2;
  const [favorites, setFavorites] = useState([]);
  const [favoritesProperties, setFavoritesProperties] = useState([]);
  const { isLoaded, error: mapsError } = useGoogleMapsScript();
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
  }, []);

  useEffect(() => {
    if (userProfile && userProfile.id !== null && userProfile.subscription?.active) {
      getUploads();
      getBillingInfo();
    }
  }, [userProfile]);

  useEffect(() => {
    const fetchFavoriteProperties = async () => {
      const favoriteProps = uploads.filter(prop => favorites.includes(prop.id));
      setFavoritesProperties(favoriteProps);
    };
    fetchFavoriteProperties();
  }, [favorites, uploads]);

  const formatNumberWithCommas = (value) => {
    if (!value) return '';
    const wholeNumber = value.toString().replace(/,/g, '').split('.')[0];
    return wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePriceChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setEditingProperty(prev => ({
      ...prev,
      price: value ? formatNumberWithCommas(value) : ''
    }));
  };

  const handleCompensationAmountChange = (e) => {
    if (editingCompensationType === 'percent') {
      const value = e.target.value.replace(/[^\d.]/g, '');
      if (/^\d*\.?\d*$/.test(value) && (!value || parseFloat(value) <= 100)) {
        setEditingCompensationAmount(value);
      }
    } else if (editingCompensationType === 'dollar') {
      const value = e.target.value.replace(/,/g, '');
      if (/^\d*$/.test(value)) {
        setEditingCompensationAmount(formatNumberWithCommas(value));
      }
    }
  };

  const handleCompensationTypeChange = (e) => {
    const newType = e.target.value;
    setEditingCompensationType(newType);
    setEditingCompensationAmount('');
  };

  const handlePlaceSelect = async (place) => {
    try {
      const results = await geocodeByAddress(place.value.description);
      if (results?.[0]) {
        const components = results[0].address_components;
        let updatedProperty = { ...editingProperty };

        for (const component of components) {
          if (component.types.includes('street_number')) {
            updatedProperty.street_address = component.long_name;
          }
          if (component.types.includes('route')) {
            updatedProperty.street_address = (updatedProperty.street_address || '') + ' ' + component.long_name;
          }
          if (component.types.includes('locality')) {
            updatedProperty.city = component.long_name;
          }
          if (component.types.includes('postal_code')) {
            updatedProperty.zip_code = component.long_name;
          }
        }

        setEditingProperty(updatedProperty);
      }
    } catch (error) {
      console.error('Error updating address:', error);
      setError('Failed to update address information');
    }
  };

  const getUploads = async () => {
    try {
      const data = await fetchUploads();
      if (Array.isArray(data)) {
        setUploads(data);
      } else {
        console.error('Fetched data is not an array:', data);
        setError('Error loading uploads');
      }
    } catch (error) {
      console.error('Error fetching uploads:', error);
      setError('Failed to load uploads');
    }
  };

  const handleToggleFavorite = (propertyId, isFavorite) => {
    setFavorites(prevFavorites => {
      const newFavorites = isFavorite
        ? [...prevFavorites, propertyId]
        : prevFavorites.filter(id => id !== propertyId);
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
      return newFavorites;
    });
  };

  const getBillingInfo = async () => {
    try {
      const data = await fetchBillingInfo();
      setBillingInfo(data);
    } catch (error) {
      console.error('Error fetching billing info:', error);
      setError('Failed to load billing information');
    }
  };

  const handleEditProperty = (property) => {
    const formattedProperty = {
      ...property,
      price: formatNumberWithCommas(Math.floor(property.price))
    };
    setEditingProperty(formattedProperty);
    setEditingCompensationType(property.compensation_type || 'dollar');
    setEditingCompensationAmount(
      property.compensation_amount ? 
      (property.compensation_type === 'percent' ? 
        property.compensation_amount : 
        formatNumberWithCommas(Math.floor(property.compensation_amount))) : 
      ''
    );
    setShowEditPropertyModal(true);
  };

  const handleDeleteProperty = async (propertyId) => {
    if (window.confirm('Are you sure you want to delete this property?')) {
      try {
        await deleteProperty(propertyId, userProfile);
        await getUploads();
      } catch (error) {
        console.error('Delete error:', error);
        setError(error.message);
      }
    }
  };

  const handleDocumentDownload = (property) => {
    if (property.pdf_file) {
      window.open(property.pdf_file, '_blank');
    } else {
      setError('No PDF document available for this property.');
    }
  };

  const handleFileInputChange = (e) => {
    const { name, files } = e.target;
    setEditingProperty(prev => ({
      ...prev,
      [name]: files[0]
    }));
  };

  const handlePropertyInputChange = (e) => {
    const { name, value } = e.target;
    setEditingProperty(prev => ({
      ...prev,
      [name]: e.target.type === 'number' ? (value === '' ? '' : Number(value)) : value
    }));
  };

  const handleUpdateBillingInfo = async () => {
    try {
      await updateBillingInfo({ billing_details: updatedBillingDetails });
      await getBillingInfo();
      setShowBillingModal(false);
    } catch (error) {
      console.error('Error updating billing info:', error);
      setError('Failed to update billing information');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordChangeMessage(null);

    if (newPassword !== confirmPassword) {
      setPasswordChangeMessage({ type: 'danger', text: 'New password and confirm password do not match.' });
      return;
    }

    try {
      const response = await changePassword(oldPassword, newPassword, confirmPassword);
      setPasswordChangeMessage({ type: 'success', text: response.message });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setPasswordChangeMessage({ type: 'danger', text: error.message });
    }
  };

  const handleSaveProperty = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      Object.keys(editingProperty).forEach(key => {
        if (editingProperty[key] !== null && editingProperty[key] !== undefined && editingProperty[key] !== '') {
          if (key === 'pdf_file' || key === 'image') {
            if (editingProperty[key] instanceof File) {
              formData.append(key, editingProperty[key]);
            }
          } else if (key === 'price') {
            formData.append(key, editingProperty[key].replace(/,/g, ''));
          } else {
            formData.append(key, editingProperty[key]);
          }
        }
      });

      // Add state from user profile
      if (userProfile?.profile?.state) {
        formData.append('state', userProfile.profile.state);
      }

      formData.append('compensation_type', editingCompensationType);
      if (['dollar', 'percent'].includes(editingCompensationType)) {
        formData.append('compensation_amount', editingCompensationAmount.replace(/,/g, ''));
      }


      const updatedProperty = await updateProperty(editingProperty.id, formData, userProfile);
      
      setUploads(prevUploads => prevUploads.map(prop =>
        prop.id === updatedProperty.id ? {
          ...updatedProperty,
          compensation_type: editingCompensationType,
          compensation_amount: editingCompensationAmount
        } : prop
      ));
      setShowEditPropertyModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
      setError(`Failed to update property: ${error.message}`);
    }
  };

  const handleEmailClick = (property) => {
    if (property.email) {
      window.location.href = `mailto:${property.email}`;
    }
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'uploads':
        return (
          <div>
            <h3>Your Uploaded Properties</h3>
            {uploads.length === 0 ? (
              <p>You haven't uploaded any properties yet.</p>
            ) : (
              <Row>
                {uploads.slice((currentPage - 1) * uploadsPerPage, currentPage * uploadsPerPage).map((property) => (
                  <Col key={property.id} md={6} className="mb-4">
                    <PropertyCardWrapper
                      property={property}
                      viewMode="grid"
                      isSubscribed={true}
                      onEmailClick={handleEmailClick}
                      onDocumentDownload={handleDocumentDownload}
                      onEdit={handleEditProperty}
                      onDelete={handleDeleteProperty}
                      currentUser={userProfile}
                      showEditDelete={true}
                    />
                  </Col>
                ))}
              </Row>
            )}
            {uploads.length > uploadsPerPage && (
              <div className="d-flex justify-content-center mt-4">
                <Pagination>
                  {Array.from({ length: Math.ceil(uploads.length / uploadsPerPage) }).map((_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index + 1 === currentPage}
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
            )}
          </div>
        );

        case 'billing':
          return (
            <div>
              <h3 className="mb-4">Billing Information</h3>
              <div className="card shadow-sm p-4 mb-4" style={{ borderRadius: '10px' }}>
                {userProfile?.subscription?.active ? (
                  <div className="subscription-info">
                    <div className="subscription-status">
                      <div className="d-flex align-items-center">
                        <div className="badge bg-success me-2 p-2">Active</div>
                        <h5 className="mb-0">
                          {userProfile.subscription.plan_type.charAt(0).toUpperCase() + 
                           userProfile.subscription.plan_type.slice(1)} Plan
                        </h5>
                      </div>
                    </div>
                    <div className="additional-info">
                      <small className="text-muted d-block mb-2">
                        Your subscription will automatically renew on{' '}
                        {new Date(userProfile.subscription.end_date).toLocaleDateString()}
                      </small>
                    </div>
                  </div>
                ) : (
                  <div className="no-subscription text-center py-4">
                    <div className="mb-3">
                      <i className="fas fa-exclamation-circle text-warning" 
                         style={{ fontSize: '2rem' }}></i>
                    </div>
                    <h5 className="mb-3">No Active Subscription</h5>
                    <p className="text-muted mb-4">
                      Subscribe now to access all features and property listings.
                    </p>
                    <StyledButton 
                      variant="primary"
                      onClick={() => setShowSubscriptionPopup(true)} 
                    >
                      Subscribe Now
                    </StyledButton>
                  </div>
                )}
              </div>
        
              {billingInfo && (
                <div className="card shadow-sm p-4 mt-4" style={{ borderRadius: '10px' }}>
                  <h5 className="mb-3">Billing Info</h5>
                  <p>{billingInfo.billing_details}</p>
                  <StyledButton 
                    variant="outline-primary" 
                    size="sm" 
                    onClick={() => setShowBillingModal(true)}
                  >
                    Update Billing Info
                  </StyledButton>
                </div>
              )}
            </div>
          );
    case 'account':
        return (
          <div>
            <h3>Account Settings</h3>
            <Form onSubmit={handlePasswordChange}>
              <Form.Group className="mb-3">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              {passwordChangeMessage && (
                <Alert variant={passwordChangeMessage.type} className="mt-3">
                  {passwordChangeMessage.text}
                </Alert>
              )}
              <StyledButton type="submit">Change Password</StyledButton>
            </Form>
          </div>
        );
      case 'favorites':
        return <SavedPropertiesList userProfile={userProfile} />;
      default:
        return null;
    }
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-grid">
      <Container>
        {error && (
          <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        <Row>
          <Col md={4}>
            <div className="options">
              <StyledOptionsList>
                <li
                  className={selectedOption === 'uploads' ? 'active' : ''}
                  onClick={() => setSelectedOption('uploads')}
                >
                  Your Uploads
                </li>
                <li
                  className={selectedOption === 'favorites' ? 'active' : ''}
                  onClick={() => setSelectedOption('favorites')}
                >
                  Saved Properties
                </li>
                <li
                  className={selectedOption === 'billing' ? 'active' : ''}
                  onClick={() => setSelectedOption('billing')}
                >
                  Billing Information
                </li>
                <li
                  className={selectedOption === 'account' ? 'active' : ''}
                  onClick={() => setSelectedOption('account')}
                >
                  Account Settings
                </li>
              </StyledOptionsList>
            </div>
          </Col>
          <Col md={8}>{renderContent()}</Col>
        </Row>
      </Container>

      <StyledModal show={showBillingModal} onHide={() => setShowBillingModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{billingInfo ? 'Update' : 'Add'} Billing Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Billing Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={updatedBillingDetails}
                onChange={(e) => setUpdatedBillingDetails(e.target.value)}
                placeholder="Enter your billing details..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBillingModal(false)}>
            Close
          </Button>
          <StyledButton onClick={handleUpdateBillingInfo}>
            {billingInfo ? 'Update' : 'Add'} Billing Info
          </StyledButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEditPropertyModal} onHide={() => setShowEditPropertyModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveProperty}>
            <Form.Group className="mb-3">
              <Form.Label>Search Address</Form.Label>
              <AutocompleteWrapper>
                {mapsError ? (
                  <Alert variant="danger">
                    Failed to load Google Maps: {mapsError.message}
                  </Alert>
                ) : !isLoaded ? (
                  <div>Loading Google Maps...</div>
                ) : (
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      onChange: handlePlaceSelect,
                      placeholder: 'Search for address...',
                    }}
                    autocompletionRequest={{
                      componentRestrictions: { country: ['us'] }
                    }}
                  />
                )}
              </AutocompleteWrapper>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                name="street_address"
                value={editingProperty?.street_address || ''}
                onChange={handlePropertyInputChange}
                required
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={editingProperty?.city || ''}
                    onChange={handlePropertyInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    value={editingProperty?.zip_code || ''}
                    onChange={handlePropertyInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={editingProperty?.description || ''}
                onChange={handlePropertyInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                value={editingProperty?.price || ''}
                onChange={handlePriceChange}
                required
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Compensation Type</Form.Label>
                  <Form.Select
                    value={editingCompensationType}
                    onChange={handleCompensationTypeChange}
                  >
                    <option value="dollar">$</option>
                    <option value="percent">%</option>
                    <option value="submit-offer">Submit Offer</option>
                    <option value="contact-agent">Contact Agent</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Compensation Amount</Form.Label>
                  {editingCompensationType === 'submit-offer' ? (
                    <Form.Control
                      type="text"
                      value="Submit Offer"
                      disabled
                      readOnly
                    />
                  ) : editingCompensationType === 'contact-agent' ? (
                    <Form.Control
                      type="text"
                      value="Contact Agent"
                      disabled
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      value={editingCompensationAmount}
                      onChange={handleCompensationAmountChange}
                      placeholder={editingCompensationType === 'percent' ? 'Enter percentage (e.g., 2.5)' : 'Enter amount'}
                      required={['dollar', 'percent'].includes(editingCompensationType)}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bedrooms</Form.Label>
                  <Form.Control
                    type="number"
                    name="bedrooms"
                    value={editingProperty?.bedrooms || ''}
                    onChange={handlePropertyInputChange}
                    min="0"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bathrooms</Form.Label>
                  <Form.Control
                    type="number"
                    name="bathrooms"
                    value={editingProperty?.bathrooms || ''}
                    onChange={handlePropertyInputChange}
                    min="0"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Square Feet</Form.Label>
              <Form.Control
                type="number"
                name="sq_ft"
                value={editingProperty?.sq_ft || ''}
                onChange={handlePropertyInputChange}
                min="0"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property URL</Form.Label>
              <Form.Control
                type="url"
                name="next_property_url"
                value={editingProperty?.next_property_url || ''}
                onChange={handlePropertyInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Contract (PDF)</Form.Label>
              <Form.Control
                type="file"
                name="pdf_file"
                onChange={handleFileInputChange}
                accept=".pdf"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileInputChange}
                accept="image/*"
              />
            </Form.Group>

            <StyledButton type="submit">Save Changes</StyledButton>
          </Form>
        </Modal.Body>
      </StyledModal>
      {showSubscriptionPopup && (
      <SubscriptionPopup
        onClose={() => setShowSubscriptionPopup(false)}
        onSubscribe={(updatedProfile) => {
          handleUserProfileUpdate(updatedProfile);
          setShowSubscriptionPopup(false);
        }}
        userProfile={userProfile}
        selectedPlan="monthly"
      />
    )}
    </div>
  );
};

export default ProfileGrid;