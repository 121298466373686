import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Pagination, ButtonGroup, Table, Modal, Form } from 'react-bootstrap';
import { 
  FaThLarge, 
  FaList, 
  FaTable, 
  FaPlus, 
  FaHeart, 
  FaEnvelope, 
  FaDownload, 
  FaExternalLinkAlt, 
  FaFolder,
  FaBed,
  FaBath,
  FaRulerCombined 
} from 'react-icons/fa';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import SubscriptionPopup from './SubscriptionPopup';
import UploadPropertyPopup from './UploadProperty';
import SignUpPopup from './SignUpPopup';
import SignInPopup from './SignInPopup';
import PropertyCardWrapper from './PropertyCardWrapper';
import { useSavedProperties } from './hooks/usePropertySavedState';
import { SavedPropertiesProvider } from './hooks/usePropertySavedState';
import SavedPropertiesList from './SavedPropertiesList';
import BatchSaveModal from './BatchSaveModal';
import placeholderImage from './placeholder.webp';
import SubscriptionOverlay from './SubscriptionOverlay';
import BlurredContent from './BlurredContent';

import {
  fetchAllProperties,
  savePropertyToFolder,
  removePropertyFromFolder,
  getFoldersContainingProperty,
  getFolders,
  getFolderProperties,
  createFolder
} from '../utils/auth';

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  
  .spinner-border {
    color: var(--color-blue-main);
    width: 3rem;
    height: 3rem;
  }
`;

const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  color: #fff;
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;

const ThumbnailImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
`;

const BatchActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 16px;

  button {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    
    &:hover, &:focus {
      background-color: var(--color-blue-main);
      border-color: var(--color-blue-main);
      opacity: 0.9;
    }
  }
`;

const UploadCard = styled(Button)`
  height: 100%;
  width: 100%;
  background-color: #f8f9fa;
  border: 2px dashed var(--color-blue-main);
  color: var(--color-blue-main);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-size: 1.5rem;
  &:hover {
    background-color: var(--color-blue-main);
    color: #fff;
  }
`;

const OverlayCard = styled.div`
  position: relative;
  height: 100%;
`;

const ActionButton = styled(Button)`
  background-color: var(--color-blue-main);
  border-color: var(--color-blue-main);
  color: white;
  
  &:hover, &:focus {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;

const QRCodeModal = styled(Modal)`
  .modal-content {
    background-color: white;
    border-radius: 12px;
    border: none;
  }
  
  .modal-header {
    background-color: var(--color-blue-main);
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: none;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .qr-image {
    width: 300px;
    height: 300px;
    margin-bottom: 1rem;
  }

  .property-address {
    text-align: center;
    font-size: 1.1rem;
    color: #666;
    margin-top: 1rem;
  }
`;
const PropertyDetailsCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .details-row {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .detail-item {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #666;
    
    svg {
      color: var(--color-blue-main);
    }
  }
`;

const DocumentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DocumentItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
  
  svg {
    color: var(--color-blue-main);
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const EmptyFavoritesState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  background: #f9fafb;
  border-radius: 12px;
  border: 2px dashed #e5e7eb;
  text-align: center;
  min-height: 300px;

  svg {
    width: 48px;
    height: 48px;
    color: #9ca3af;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 8px;
  }

  p {
    color: #6b7280;
    margin-bottom: 24px;
  }

  button {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    padding: 8px 24px;
    
    &:hover, &:focus {
      background-color: var(--color-blue-main);
      border-color: var(--color-blue-main);
      opacity: 0.9;
    }
  }
`;

const Feed = ({ userProfile, onUserProfileUpdate, searchResults }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [showUploadPropertyPopup, setShowUploadPropertyPopup] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [isLoading, setIsLoading] = useState(false);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const itemsPerPage = 10;
  const { savedPropertyIds, refreshSavedProperties } = useSavedProperties();
  const [selectedProperties, setSelectedProperties] = useState(new Set());
  const [folders, setFolders] = useState([]);
  const [showBatchSaveModal, setShowBatchSaveModal] = useState(false);
  const [batchSaveLoading, setBatchSaveLoading] = useState(false);

  const handleViewModeChange = (mode) => {
    if (mode === 'favorites') {
      if (!userProfile) {
        setShowSignInPopup(true);
        return;
      }

      setViewMode('favorites');
    } else {
      setViewMode(mode);
    }
  };

  const loadFolders = async () => {
    try {
      const foldersData = await getFolders();
      setFolders(foldersData);
    } catch (error) {
      console.error('Error loading folders:', error);
    }
  };

  useEffect(() => {
    loadFolders();
  }, []);

  const handleToggleFavorite = useCallback(async (propertyId, isFavorite) => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }

    try {
      if (isFavorite) {
        await savePropertyToFolder(propertyId);
      } else {
        const folders = await getFoldersContainingProperty(propertyId);
        for (const folder of folders) {
          await removePropertyFromFolder(folder.id, propertyId);
        }
      }
      await refreshSavedProperties();
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  }, [refreshSavedProperties, userProfile]);

  const checkSubscriptionStatus = useCallback(() => {


    if (userProfile?.subscription) {
      setIsSubscribed(userProfile.subscription.active);
    } else {
      setIsSubscribed(false);
    }
  }, [userProfile]);

  const loadAllProperties = useCallback(async () => {
    if (!userProfile) return;

    setIsLoading(true);
    try {
      const response = await fetchAllProperties(userProfile);
      const propertiesList = Array.isArray(response)
        ? response
        : response.properties || [];
      setProperties(propertiesList);
    } catch (error) {
      console.error('Error fetching properties:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile) {
      loadAllProperties();
    }
  }, [userProfile, loadAllProperties]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      const searchResultsList = Array.isArray(searchResults)
        ? searchResults
        : searchResults.properties || [];
      setProperties(searchResultsList);
      setCurrentPage(1);
    } else if (!searchResults && userProfile) {
      loadAllProperties();
    }

    checkSubscriptionStatus();
  }, [loadAllProperties, checkSubscriptionStatus, searchResults, userProfile]);

  const handleUploadClick = () => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }
    if (!userProfile.subscription?.active) {
      setShowSubscriptionPopup(true);
      return;
    }
    setShowUploadPropertyPopup(true);
  };

  const handleBatchSave = async (folderId, notes) => {
    setBatchSaveLoading(true);
    try {
      for (const propertyId of Array.from(selectedProperties)) {
        await savePropertyToFolder(propertyId, {
          folder_id: folderId,
          notes
        });
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      await refreshSavedProperties();
      setShowBatchSaveModal(false);
      setSelectedProperties(new Set());
    } catch (error) {
      console.error('Failed to save properties to folder:', error);
    } finally {
      setBatchSaveLoading(false);
    }
  };

  const handleCreateFolder = async (name, description) => {
    try {
      const newFolder = await createFolder(name, description);
      await loadFolders();
      return newFolder;
    } catch (error) {
      console.error('Failed to create folder:', error);
      throw new Error('Failed to create folder');
    }
  };

  const handleSubscriptionSuccess = async (updatedProfile) => {
    onUserProfileUpdate(updatedProfile);
    setIsSubscribed(true);
    setShowSubscriptionPopup(false);
    loadAllProperties();
  };

  const handleCloseUploadPropertyPopup = async (uploadedProperty) => {
    setShowUploadPropertyPopup(false);
    if (uploadedProperty) {
      setProperties(prevProperties =>
        Array.isArray(prevProperties)
          ? [uploadedProperty, ...prevProperties]
          : [uploadedProperty]
      );
    } else {
      await loadAllProperties();
    }
  };

  const handleDocumentDownload = (property) => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }
    if (!isSubscribed) {
      setShowSubscriptionPopup(true);
      return;
    }
    if (property.pdf_file) {
      window.open(property.pdf_file, '_blank');
    }
  };

  const handleEmailClick = (property) => {
    if (!userProfile) {
      setShowSignInPopup(true);
      return;
    }
    const subject = `Inquiry about property: ${property.street_address}, ${property.city}`;
    const body = `Hi,\n\nI'm interested in the property at ${property.street_address}, ${property.city}. Can you please provide more information?\n\nThank you,\n${userProfile.username}`;
    const mailtoLink = `mailto:${property.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };


  const PropertyTable = ({
    properties,
    onEmailClick,
    selectedProperties,
    onSelectProperties,
    onShowBatchSave,
    savedPropertyIds
  }) => {
    const [showQRModal, setShowQRModal] = useState(false);
    const [selectedQRUrl, setSelectedQRUrl] = useState('');
    const [selectedPropertyAddress, setSelectedPropertyAddress] = useState('');

    const handleQRCodeClick = (url, address) => {
      setSelectedQRUrl(url);
      setSelectedPropertyAddress(address);
      setShowQRModal(true);
    };

    const renderDescription = (property) => {
      if (!isSubscribed) {
        return '******* Subscribe to view details *******';
      }
    
      return property.description.length > 100 
        ? `${property.description.substring(0, 100)}...` 
        : property.description;
    };
    const handleSelectAll = (e) => {
      if (e.target.checked) {
        const selectableProperties = properties
          .filter(p => !savedPropertyIds.has(p.id))
          .map(p => p.id);
        onSelectProperties(new Set(selectableProperties));
      } else {
        onSelectProperties(new Set());
      }
    };

    const handleSelectProperty = (propertyId) => {
      const newSelected = new Set(selectedProperties);
      if (newSelected.has(propertyId)) {
        newSelected.delete(propertyId);
      } else {
        newSelected.add(propertyId);
      }
      onSelectProperties(newSelected);
    };

    const formatNumber = (num) => {
      return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatCompensation = (property) => {
      if (!isSubscribed) {
        return '****'; 
      }
      const metadata = localStorage.getItem(`property_${property.id}_metadata`);
      const storedData = metadata ? JSON.parse(metadata) : null;

      const selectedOption = storedData?.selectedOption || property.selectedOption;
      const concessionType = storedData?.concessionType || property.concessionType;
      const concession = property.concession;

      if (selectedOption === 'contact-agent' || !concession) {
        return 'Contact Agent';
      }

      const formattedValue = formatNumber(concession);
      return concessionType === 'percent' ?
        `${formattedValue}%` :
        `${formattedValue}`;
    };

    if (!properties || properties.length === 0) {
      return (
        <LoadingSpinner>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </LoadingSpinner>
      );
    }
    const renderPropertyDetails = (property) => (
      <PropertyDetailsCell>
        <OverlayTrigger
          placement="top"
          overlay={!isSubscribed ? <Tooltip>Subscribe to view property details</Tooltip> : <></>}
        >
          <div>
            <BlurredContent
              isSubscribed={isSubscribed}
              message="Subscribe to view property details"
            >
              {property.description && (
  <div className="property-description">
    <OverlayTrigger
      placement="top"
      overlay={!isSubscribed ? <Tooltip>Subscribe to view property details</Tooltip> : <></>}
    >
      <div>
        {renderDescription(property)}
      </div>
    </OverlayTrigger>
  </div>
)}
            </BlurredContent>
          </div>
        </OverlayTrigger>
      </PropertyDetailsCell>
    );

    const renderDocuments = (property) => (
      <DocumentsList>
        <OverlayTrigger
          placement="top"
          overlay={!isSubscribed ? <Tooltip>Subscribe to view property documents</Tooltip> : <></>}
        >
          <BlurredContent isBlurred={!isSubscribed}>
          {property.pdf_file && (
  <ActionButton
    size="sm"
    onClick={() => isSubscribed ? window.open(property.pdf_file, '_blank') : setShowSubscriptionPopup(true)}
    disabled={!isSubscribed}
  >
    <FaDownload /> {isSubscribed ? 'Documents' : 'Subscribe to View'}
  </ActionButton>
)}
          </BlurredContent>
        </OverlayTrigger>
      </DocumentsList>
    );

    return (
      <>
        {selectedProperties.size > 0 && (
          <BatchActionBar>
            <span>{selectedProperties.size} properties selected</span>
            <ActionButton
              variant="primary"
              size="sm"
              onClick={onShowBatchSave}
            >
              <FaFolder className="me-2" />
              Add to Folder
            </ActionButton>
          </BatchActionBar>
        )}

        <Table striped bordered hover>
          <thead>
            <tr style={{ backgroundColor: 'var(--color-blue-main)', color: 'white' }}>
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedProperties.size === properties.filter(p => !savedPropertyIds.has(p.id)).length}
                />
              </th>
              <th>Property</th>
  <th>Price</th>
  <th>Compensation</th>
  <th>Property Details</th>
  <th>Property Size</th>
  <th>QR Code</th>
  <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {properties.map((property) => {
              const sizeInfo = [
                property.bedrooms ? `${Math.floor(property.bedrooms)} bed` : null,
                property.bathrooms ? `${Math.floor(property.bathrooms)} bath` : null,
                property.sq_ft ? `${formatNumber(property.sq_ft)} sqft` : null
              ].filter(Boolean).join(' || ');

              const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${encodeURIComponent(property.next_property_url || window.location.href)}`;
              const propertyAddress = `${property.street_address}, ${property.city}, ${property.zip_code}`;

              return (
                <tr key={property.id}>
                  <td>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        savedPropertyIds.has(property.id) ? (
                          <Tooltip id={`tooltip-${property.id}`}>
                            This property has already been saved
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div>
                        <Form.Check
                          type="checkbox"
                          checked={selectedProperties.has(property.id)}
                          onChange={() => handleSelectProperty(property.id)}
                          disabled={savedPropertyIds.has(property.id)}
                          style={{
                            cursor: savedPropertyIds.has(property.id) ? 'not-allowed' : 'pointer',
                            opacity: savedPropertyIds.has(property.id) ? '0.6' : '1',
                            backgroundColor: savedPropertyIds.has(property.id) ? '#f8f9fa' : 'transparent',
                            padding: '4px',
                            borderRadius: '4px'
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <ThumbnailImage
                        src={property.image || placeholderImage}
                        alt={property.street_address || 'Placeholder Image'}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = placeholderImage;
                        }}
                      />
                      <div>
                        {property.street_address}, {property.city}, {property.zip_code}
                      </div>
                    </div>
                  </td>
                  <td>${formatNumber(property.price)}</td>
                  <td>
                    <BlurredContent
                      isSubscribed={isSubscribed}
                      message="Subscribe to view compensation"
                    >
                      {formatCompensation(property)}
                    </BlurredContent>
                  </td>
                  <td>{renderPropertyDetails(property)}</td>
                  <td>{sizeInfo}</td>
                  <td>
                    <img
                      src={qrCodeUrl}
                      alt="Property QR Code"
                      style={{
                        width: '50px',
                        height: '50px',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleQRCodeClick(qrCodeUrl, propertyAddress)}
                    />
                  </td>
                  <td>
  <ButtonContainer>
    {property.next_property_url && (
      <ActionButton
        size="sm"
        href={property.next_property_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaExternalLinkAlt /> URL
      </ActionButton>
    )}
    <ActionButton
      size="sm"
      onClick={() => onEmailClick(property)}
    >
      <FaEnvelope /> Email
    </ActionButton>
    {property.pdf_file && (
      <BlurredContent
        isSubscribed={isSubscribed}
        message="Subscribe to view documents"
      >
        <ActionButton
          size="sm"
          onClick={() => isSubscribed ? window.open(property.pdf_file, '_blank') : null}
          disabled={!isSubscribed}
        >
          <FaDownload /> Docs
        </ActionButton>
      </BlurredContent>
    )}
  </ButtonContainer>
</td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <QRCodeModal
          show={showQRModal}
          onHide={() => setShowQRModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Property QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={selectedQRUrl}
              alt="Large QR Code"
              className="qr-image"
            />
            <div className="property-address">
              {selectedPropertyAddress}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ActionButton onClick={() => setShowQRModal(false)}>
              Close
            </ActionButton>
          </Modal.Footer>
        </QRCodeModal>
      </>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = properties.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return (
      <LoadingSpinner>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </LoadingSpinner>
    );
  }

  const FavoritesFallback = () => (
    <EmptyFavoritesState>
      <FaHeart size={48} />
      <h4>Sign in to View Saved Properties</h4>
      <p>Log in to access your saved properties and organized folders</p>
      <Button onClick={() => setShowSignInPopup(true)}>
        Sign In
      </Button>
    </EmptyFavoritesState>
  );

  return (
    <SavedPropertiesProvider>
      <div className="feed">
        <Container>
          <div className="d-flex justify-content-end align-items-center mb-3">
            <ButtonGroup>
              {['grid', 'table', 'favorites'].map((mode) => (
                <Button
                  key={mode}
                  variant={viewMode === mode ? 'primary' : 'outline-primary'}
                  onClick={() => handleViewModeChange(mode)}
                  style={{
                    backgroundColor: viewMode === mode ? 'var(--color-blue-main)' : 'transparent',
                    borderColor: 'var(--color-blue-main)',
                    color: viewMode === mode ? '#fff' : 'var(--color-blue-main)'
                  }}
                >
                  {mode === 'grid' && <FaThLarge />}
                  {mode === 'table' && <FaTable />}
                  {mode === 'favorites' && <FaHeart />}
                  {` ${mode.charAt(0).toUpperCase() + mode.slice(1)}`}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          <Row>
            {viewMode !== 'table' && viewMode !== 'favorites' && (
              <Col lg={6} xl={4} className="mb-4">
                <OverlayCard>
                  <UploadCard onClick={handleUploadClick}>
                    <FaPlus />
                    <div>Upload Property</div>
                  </UploadCard>
                  {(!userProfile || !userProfile.subscription?.active) && (
                    <SubscriptionOverlay
                      userProfile={userProfile}
                      onSubscribeClick={(updatedProfile) => {
                        onUserProfileUpdate(updatedProfile);
                        setIsSubscribed(true);
                      }}
                    />
                  )}
                </OverlayCard>
              </Col>
            )}

            {viewMode === 'table' ? (
              <Col xs={12}>
                <PropertyTable
                  properties={currentItems}
                  folders={folders}
                  onEmailClick={handleEmailClick}
                  selectedProperties={selectedProperties}
                  onSelectProperties={setSelectedProperties}
                  onShowBatchSave={() => setShowBatchSaveModal(true)}
                  savedPropertyIds={savedPropertyIds}
                  isSubscribed={isSubscribed} // Add this prop
                />
              </Col>
            ) : viewMode === 'favorites' ? (
              <Col xs={12}>
                {userProfile ? (
                  <SavedPropertiesList
                    userProfile={userProfile}
                    onEmailClick={handleEmailClick}
                    onDocumentDownload={handleDocumentDownload}
                  />
                ) : (
                  <FavoritesFallback />
                )}
              </Col>
            ) : (
              currentItems.map((property) => (
                <Col key={property.id} lg={6} xl={4} className="mb-4">
                  <PropertyCardWrapper
                    property={property}
                    viewMode="grid"
                    isSubscribed={isSubscribed}
                    onEmailClick={handleEmailClick}
                    onDocumentDownload={handleDocumentDownload}
                    currentUser={userProfile}
                    isUploadSection={false}
                    onToggleFavorite={handleToggleFavorite}
                    isFavorite={savedPropertyIds.has(property.id)}
                  />
                </Col>
              ))
            )}
          </Row>

          {(viewMode === 'grid' || viewMode === 'table') && (
            <div className="d-flex justify-content-center mt-4">
              <Pagination>
                {Array.from({ length: Math.ceil(properties.length / itemsPerPage) }).map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          )}
        </Container>

        {showSignInPopup && (
          <SignInPopup
            onClose={() => setShowSignInPopup(false)}
            onSignInSuccess={(user) => {
              onUserProfileUpdate(user);
              setShowSignInPopup(false);
              if (viewMode === 'favorites') {
                setViewMode('favorites');
              }
            }}
            onSignUpClick={() => {
              setShowSignInPopup(false);
              // Handle sign up click if needed
            }}
          />
        )}

        {showSubscriptionPopup && (
          <SubscriptionPopup
            onClose={() => setShowSubscriptionPopup(false)}
            onSubscribe={handleSubscriptionSuccess}
            userProfile={userProfile}
          />
        )}

        <BatchSaveModal
          isOpen={showBatchSaveModal}
          onClose={() => setShowBatchSaveModal(false)}
          selectedProperties={selectedProperties}
          folders={folders}
          onSave={handleBatchSave}
          loading={batchSaveLoading}
          onCreateFolder={handleCreateFolder}
        />

        <UploadPropertyPopup
          show={showUploadPropertyPopup}
          onClose={() => setShowUploadPropertyPopup(false)}
          onUploadSuccess={handleCloseUploadPropertyPopup}
          userProfile={userProfile}
        />
      </div>
    </SavedPropertiesProvider>
  );
};

export default Feed;