import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import SignUpPopup from './SignUpPopup';
import SignInPopup from './SignInPopup';
import SubscriptionPopup from './SubscriptionPopup';

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  border-color: var(--color-blue-main);
  &:hover, &:focus {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;

const SubscriptionOverlay = ({ userProfile, onSubscribeClick }) => {
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  const handleSubscribeClick = () => {
    if (!userProfile) {
      setShowSignUpPopup(true);
    } else {
      setShowSubscriptionPopup(true);
    }
  };

  const handleSignInClick = () => {
    setShowSignUpPopup(false);
    setShowSignInPopup(true);
  };

  const handleSignUpClick = () => {
    setShowSignInPopup(false);
    setShowSignUpPopup(true);
  };

  const handleSignInSuccess = (userData) => {
    setShowSignInPopup(false);
    setShowSubscriptionPopup(true);
  };

  return (
    <>
      <OverlayContainer>
        <h4>Subscribe to Upload Properties</h4>
        <StyledButton onClick={handleSubscribeClick}>
          Subscribe Now
        </StyledButton>
      </OverlayContainer>

      {showSignUpPopup && (
        <SignUpPopup
          onClose={() => setShowSignUpPopup(false)}
          onSignInClick={handleSignInClick}
          onSignUpSuccess={() => {
            setShowSignUpPopup(false);
            setShowSubscriptionPopup(true);
          }}
        />
      )}

      {showSignInPopup && (
        <SignInPopup
          onClose={() => setShowSignInPopup(false)}
          onSignUpClick={handleSignUpClick}
          onSignIn={handleSignInSuccess}
          onSubscribeClick={() => setShowSubscriptionPopup(true)}
        />
      )}

      {showSubscriptionPopup && (
        <SubscriptionPopup
          onClose={() => setShowSubscriptionPopup(false)}
          onSubscribe={(updatedProfile) => {
            setShowSubscriptionPopup(false);
            if (onSubscribeClick) {
              onSubscribeClick(updatedProfile);
            }
          }}
          userProfile={userProfile}
        />
      )}
    </>
  );
};

export default SubscriptionOverlay;