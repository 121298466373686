import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ onPaymentSuccess, amount, planType, userProfile }) => {

  
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        onPaymentSuccess={onPaymentSuccess} 
        amount={amount}
        planType={planType}
        userProfile={userProfile}
      />
    </Elements>
  );
};
export default PaymentForm;