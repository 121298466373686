import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { updateUserProfile, activateSubscription } from '../utils/auth';
import PaymentForm from './Payment';
import SubscriptionResult from './SubscriptionResult';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { X } from 'lucide-react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Styled Components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  max-width: 900px;
  width: 90%;
  max-height: 90vh;
  position: relative;
`;

const PopupContent = styled.div`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;

  h2 {
    color: var(--color-blue-main);
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const PopupRight = styled.div`
  width: 300px;
  background-color: var(--color-blue-main);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 2rem;
  color: white;
  position: relative;

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.5rem;

    &:hover {
      opacity: 0.8;
    }
  }

  .savings-note {
    margin-top: 2rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;

    p {
      margin: 0.5rem 0;
      font-size: 0.9rem;
    }
  }
`;

const StyledButton = styled.button`
  background-color: var(--color-blue-main);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    opacity: 0.9;
  }
`;

const PlanToggle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto 2rem;
`;

const PlanOption = styled.button`
  padding: 8px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background: ${props => props.active ? 'var(--color-blue-main)' : 'transparent'};
  color: ${props => props.active ? 'white' : '#666'};
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.active ? 'var(--color-blue-main)' : '#e0e0e0'};
  }
`;

const PriceDisplay = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  .amount {
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-blue-main);
  }

  .period {
    color: #666;
    margin-left: 4px;
  }

  .savings {
    font-size: 0.9rem;
    color: #4CAF50;
    margin-top: 0.5rem;
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
`;

const AddressInput = styled.input`
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  &:focus {
    outline: none;
    border-bottom: 2px solid var(--color-blue-main);
  }
  &:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
  }
`;

const FormElements = styled.form`
  position: relative;
  width: 100%;
`;

const ResetButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #333;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  font-size: 16px;
  &:focus {
    outline: none;
    border-bottom: 2px solid var(--color-blue-main);
  }
  margin-bottom: 1rem;
`;

const SuggestionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1001;
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  border-radius: 0 0 4px 4px;
`;
const styles = `
  #placesServiceMap {
    display: none;
  }
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const LoadingText = styled.div`
  color: #666;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  font-size: 14px;
  margin: 8px 0;
  padding: 8px;
  background-color: #f8d7da;
  border-radius: 4px;
  border: 1px solid #f5c6cb;
`;

const SubscriptionPopup = ({ onClose, onSubscribe, userProfile, selectedPlan = 'monthly' }) => {
  // State declarations
  const [name, setName] = useState(userProfile?.name || '');
  const [email] = useState(userProfile?.email || '');
  const [phone, setPhone] = useState(userProfile?.phone || '');
  const [company, setCompany] = useState(userProfile?.company || '');
  const [stateId, setStateId] = useState(userProfile?.state_id || '');
  const [businessAddress, setBusinessAddress] = useState(userProfile?.business_address || '');
  const [suggestions, setSuggestions] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [error, setError] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(selectedPlan);

  const addressInputRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  // Load Google Maps Script
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          setIsGoogleLoaded(true);
          initializeGoogleServices();
        };
        document.head.appendChild(script);
      } else {
        setIsGoogleLoaded(true);
        initializeGoogleServices();
      }
    };

    const initializeGoogleServices = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
     
        
        // Initialize AutocompleteService
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
        
        // Create map div for PlacesService
        let mapDiv = document.getElementById('placesServiceMap');
        if (!mapDiv) {
          mapDiv = document.createElement('div');
          mapDiv.style.display = 'none';  // Hide the div
          mapDiv.id = 'placesServiceMap';
          document.body.appendChild(mapDiv);
        }
        
        // Initialize PlacesService
        placesService.current = new window.google.maps.places.PlacesService(mapDiv);
        

      }
    };
  
    loadGoogleMapsScript();
    
    // Cleanup function
    return () => {
      const mapDiv = document.getElementById('placesServiceMap');
      if (mapDiv) {
        mapDiv.remove();
      }
    };
  }, []);


  // Click outside handler for suggestions list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addressInputRef.current && 
        !addressInputRef.current.contains(event.target) &&
        !event.target.closest('.suggestions-list')
      ) {
        setSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handlers
  const handleAddressChange = (e) => {
    const value = e.target.value;
    setBusinessAddress(value);
    
    if (value.length > 2 && isGoogleLoaded && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { 
          input: value, 
          componentRestrictions: { country: 'us' } 
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };
  const resetAddress = () => {
    setBusinessAddress('');
    setSuggestions([]);
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  const handleSuggestionSelect = (placeId) => {
    
    
    if (!placesService.current) {
      console.error('Places service not initialized');
      return;
    }
  
    try {
      // Create map div if it doesn't exist
      let mapDiv = document.getElementById('placesServiceMap');
      if (!mapDiv) {
        mapDiv = document.createElement('div');
        mapDiv.id = 'placesServiceMap';
        document.body.appendChild(mapDiv);
        placesService.current = new window.google.maps.places.PlacesService(mapDiv);
      }
  
      // Request with all required address fields
      const request = {
        placeId: placeId,
        fields: ['formatted_address', 'address_components', 'name']
      };
  
      placesService.current.getDetails(request, (place, status) => {
       
        
        if (status === 'OK' && place) {
          if (place.formatted_address) {
            setBusinessAddress(place.formatted_address);
          } else if (place.name) {
            setBusinessAddress(place.name);
          }
          setSuggestions([]);
        } else {
          console.error('Error getting place details:', status);
        }
      });
    } catch (error) {
      console.error('Error in getDetails:', error);
    }
  };
  
  

  const handlePlanChange = (plan) => {
    setSubscriptionPlan(plan);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const updatedProfileData = {
        name,
        phone,
        company,
        state: userProfile.profile.state,
        state_id: stateId,
        business_address: businessAddress
      };
      await updateUserProfile(updatedProfileData);
      setShowPayment(true);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  const handlePaymentSuccess = async (paymentMethodId) => {

    try {
      const subscriptionData = {
        paymentMethodId,
        planType: subscriptionPlan,
        name,
        email,
        phone,
        company,
        state: userProfile.profile.state,
        state_id: stateId,
        business_address: businessAddress
      };
  
      
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscribe/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscriptionData)
      });
  
      const result = await response.json();
   
  
      if (!response.ok) {
        const errorMessage = result.detail || result.message || result.error || 'Subscription activation failed';
        console.error('API error response:', result);
        throw new Error(`Failed to make payment: ${errorMessage}`);
      }
  
      if (result.subscription?.active) {
   
        const updatedProfile = {
          ...userProfile,
          name: result.user?.name || name,
          subscription: result.subscription,
          profile: {
            ...userProfile.profile,
            ...result.profile
          }
        };
        
        // Update global user profile
        onSubscribe(updatedProfile);
        
        // Show success message
        setActivationSuccess(true);
        setResultMessage(`Successfully subscribed to ${subscriptionPlan} plan!`);
        setShowResult(true);
  
        // Add a longer delay to ensure message is visible
        setTimeout(() => {
          
          onClose();
        }, 5000);
      } else {
        console.error('Subscription not active in response:', result);
        throw new Error('Failed to make payment: Subscription activation failed');
      }
    } catch (error) {
      console.error('Error in subscription process:', error);
      setActivationSuccess(false);
      // Clean up error message if it contains "Request"
      const errorMessage = error.message.includes('Request') 
        ? `Failed to make payment: ${error.message.split(': ')[2]}`
        : error.message;
      setResultMessage(errorMessage);
      setShowResult(true);
  
      // Keep error message visible for a while before allowing retry
      setTimeout(() => {
        setShowResult(false);
      }, 5000);
    }
  };
  
  const handleResultClose = () => {
    
    setShowResult(false);
    if (activationSuccess) {
      onClose();
    }
  };

  // Render
  return (
    <PopupOverlay>
      <PopupContainer>
        {showResult ? (
        <SubscriptionResult
          success={activationSuccess}
          message={resultMessage}
          onClose={handleResultClose}
        />
        ) : (
          <>
            <PopupContent>
              <h2><span>Subscribe Now</span></h2>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {!showPayment ? (
                <>
                  <PlanToggle>
                    <PlanOption 
                      active={subscriptionPlan === 'monthly'} 
                      onClick={() => handlePlanChange('monthly')}
                    >
                      Monthly
                    </PlanOption>
                    <PlanOption 
                      active={subscriptionPlan === 'annual'} 
                      onClick={() => handlePlanChange('annual')}
                    >
                      Yearly
                    </PlanOption>
                  </PlanToggle>

                  <PriceDisplay>
                    <span className="amount">
                      ${subscriptionPlan === 'monthly' ? '6.99' : '30.00'}
                    </span>
                    <span className="period">
                      /{subscriptionPlan === 'monthly' ? 'month' : 'year'}
                    </span>
                    {subscriptionPlan === 'annual' && (
                      <div className="savings">Save 16% with annual billing</div>
                    )}
                  </PriceDisplay>

                  <p>Complete Your Agent Profile to Subscribe</p>
                  <FormElements onSubmit={handleSubmit}>
                    <StyledInput
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <StyledInput
                      type="email"
                      placeholder="Email"
                      value={email}
                      readOnly
                    />
                    <StyledInput
                      type="tel"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <StyledInput
                      type="text"
                      placeholder="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                    <StyledInput
                      type="text"
                      placeholder="State ID"
                      value={stateId}
                      onChange={(e) => setStateId(e.target.value)}
                      required
                    />
                    <AutocompleteWrapper>
                      <AddressInput
                        ref={addressInputRef}
                        type="text"
                        placeholder={!isGoogleLoaded ? "Loading Google Maps..." : "Business Address"}
                        value={businessAddress}
                        onChange={handleAddressChange}
                        disabled={!isGoogleLoaded}
                        required
                      />
                      {businessAddress && (
  <ResetButton onClick={resetAddress} type="button">
    <X size={16} />
  </ResetButton>
)}
                      {!isGoogleLoaded && (
                        <LoadingText>Loading Google Maps integration...</LoadingText>
                      )}
                      {suggestions.length > 0 && (
  <SuggestionsList className="suggestions-list">
    {suggestions.map((suggestion) => (
      <SuggestionItem
        key={suggestion.place_id}
        onClick={() => {
       
          handleSuggestionSelect(suggestion.place_id);
        }}
      >
        {suggestion.description}
      </SuggestionItem>
    ))}
  </SuggestionsList>
)}
                    </AutocompleteWrapper>
                    <StyledButton type="submit">Continue to Payment</StyledButton>
                  </FormElements>
                </>
              ) : (
                <Elements stripe={stripePromise}>
                  <PaymentForm 
                    onPaymentSuccess={handlePaymentSuccess}
                    amount={subscriptionPlan === 'monthly' ? 6.99 : 30.00}
                    planType={subscriptionPlan}
                    userProfile={{
                      name,
                      email,
                      profile: {
                        phone,
                        company,
                        state: userProfile?.profile?.state,
                        state_id: stateId,
                        business_address: businessAddress
                      }
                    }}
                  />
                </Elements>
              )}
            </PopupContent>
            <PopupRight>
              <button className="close-button" onClick={onClose}>
                <FaTimes />
              </button>
              <div className="popup-right-content">
                <p className="filler-text">Subscribe to upload and view property details!</p>
                {subscriptionPlan === 'annual' && (
                  <div className="savings-note">
                    <p>Save 16% with annual billing</p>
                    <p>Only $2.50/month when billed annually</p>
                  </div>
                )}
              </div>
            </PopupRight>
          </>
        )}
      </PopupContainer>
    </PopupOverlay>
  );
};

export default SubscriptionPopup;