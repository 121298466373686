import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const BlurredContent = ({ isSubscribed, children, message = "Subscribe to view content" }) => {
  const ContentWrapper = ({ children }) => (
    <div
      className={!isSubscribed ? "select-none pointer-events-none" : ""}
      style={{
        filter: !isSubscribed ? "blur(4px)" : "none",
      }}
    >
      {children}
    </div>
  );

  if (!isSubscribed) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{message}</Tooltip>}
      >
        <div>
          <ContentWrapper>{children}</ContentWrapper>
        </div>
      </OverlayTrigger>
    );
  }

  return <ContentWrapper>{children}</ContentWrapper>;
};

export default BlurredContent;