import axios from 'axios';

const createAdminAPIInstance = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Authorization': `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
    }
  });
  return api;
};
const BACKEND_URL = process.env.REACT_APP_API_URL;
const createAPIInstance = (userProfile) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }

    if (userProfile?.profile?.state) {
      config.headers['X-User-State'] = userProfile.profile.state;
    }

    return config;
  });

  return api;
};

const defaultInstance = createAPIInstance();

export const register = async (username, email, password, stateId) => {
  try {
    const response = await defaultInstance.post('/api/register/', {
      username,
      email,
      password,
      profile: { state: stateId }  
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw new Error('No response received from the server');
    } else {
      throw new Error('Error setting up the request');
    }
  }
};

export const verifyEmail = async (uidb64, token) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/verify-email/${uidb64}/${token}/`;
    const response = await axios.get(url);
    return { 
      success: true, 
      message: response.data.message || 'Email verified successfully. You can now log in.' 
    };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.error || 'Registration link expired or invalid. Please try again.'
    };
  }
};

export const login = async (username, password) => {
  try {
    const api = createAPIInstance();
    const response = await api.post('api/login/', { username, password });
    
    if (response.data?.token) {
      localStorage.setItem('token', response.data.token);
      return response.data;
    }
    throw new Error('Invalid response from server');
  } catch (error) {
    throw error;
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await defaultInstance.post('api/password-reset/', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (uidb64, token, newPassword) => {
  try {
    const response = await defaultInstance.post(`api/password-reset-confirm/${uidb64}/${token}/`, {
      new_password: newPassword
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'An error occurred while resetting the password.');
    } else {
      throw new Error('An unexpected error occurred.');
    }
  }
};

export const fetchUserProfile = async (userId = null) => {
  try {
    const endpoint = userId ? `api/user/profile/${userId}/` : 'api/user/profile/';
    const profileResponse = await defaultInstance.get(endpoint);
    const subscriptionResponse = await defaultInstance.get('api/user/subscription/');
    
    const userProfile = {
      ...profileResponse.data,
      name: profileResponse.data.name || `${profileResponse.data.first_name || ''} ${profileResponse.data.last_name || ''}`.trim(),
      subscription: subscriptionResponse.data
    };
    return userProfile;
  } catch (error) {
    throw error;
  }
};

export const fetchAllProperties = async (userProfile) => {
  const api = userProfile ? createAPIInstance(userProfile) : defaultInstance;
  
  try {
    const initialResponse = await api.get('api/properties/', {
      params: {
        page: 1,
        limit: 100,
        state: userProfile?.profile?.state
      },
      headers: {
        'X-User-State': userProfile?.profile?.state,
        'State': userProfile?.profile?.state
      }
    });

    let allProperties = Array.isArray(initialResponse.data) ? 
      initialResponse.data : 
      initialResponse.data.properties || [];

    allProperties = allProperties.map(property => ({
      ...property,
      state: property.state || userProfile?.profile?.state
    }));

    if (initialResponse.data.total_pages) {
      const totalPages = initialResponse.data.total_pages;
      const remainingRequests = [];
      
      for (let page = 2; page <= totalPages; page++) {
        remainingRequests.push(
          api.get('api/properties/', {
            params: {
              page: page,
              limit: 100,
              state: userProfile?.profile?.state
            },
            headers: {
              'X-User-State': userProfile?.profile?.state,
              'State': userProfile?.profile?.state
            }
          })
        );
      }

      if (remainingRequests.length > 0) {
        const remainingResponses = await Promise.all(remainingRequests);
        remainingResponses.forEach(response => {
          const pageProperties = Array.isArray(response.data) ? 
            response.data : 
            response.data.properties || [];
            
          const propertiesWithState = pageProperties.map(property => ({
            ...property,
            state: property.state || userProfile?.profile?.state
          }));
          
          allProperties = [...allProperties, ...propertiesWithState];
        });
      }
    }

    return {
      properties: allProperties
    };

  } catch (error) {
    throw error;
  }
};

export const uploadProperty = async (formData, userProfile, onProgress) => {
  const api = createAPIInstance(userProfile);
  
  if (userProfile?.profile?.state) {
    formData.delete('state');
    formData.set('state', userProfile.profile.state);
  }

  try {
    const response = await api.post('api/properties/', formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        'X-User-State': userProfile?.profile?.state,
        'State': userProfile?.profile?.state
      },
      onUploadProgress: (e) => {
        if (onProgress && typeof onProgress === 'function') {
          onProgress(Math.round((e.loaded * 100) / e.total));
        }
      }
    });

    if (!response.data.state && userProfile?.profile?.state) {
      response.data.state = userProfile.profile.state;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (updatedFields) => {
  try {
    const dataToUpdate = {
      name: updatedFields.name,
      profile: {}
    };

    if (updatedFields.phone) dataToUpdate.profile.phone = updatedFields.phone;
    if (updatedFields.company) dataToUpdate.profile.company = updatedFields.company;
    if (updatedFields.state) dataToUpdate.profile.state = updatedFields.state;
    if (updatedFields.state_id) dataToUpdate.profile.state_id = updatedFields.state_id;
    if (updatedFields.business_address) dataToUpdate.profile.business_address = updatedFields.business_address;

    const response = await defaultInstance.put('api/user/profile/update/', dataToUpdate);

    const updatedProfile = {
      ...response.data.user,
      name: response.data.user.name || updatedFields.name,
      profile: {
        ...response.data.user.profile
      }
    };

    return { user: updatedProfile };
  } catch (error) {
    throw error;
  }
};

export const fetchUploads = async () => {
  try {
    const response = await defaultInstance.get('api/user/uploads/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const activateSubscription = async (paymentMethodId, subscriptionData) => {
  

  try {
    const response = await fetch(`${BACKEND_URL}/api/subscribe/`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId,
        ...subscriptionData
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Subscription activation failed:', errorData);
      throw new Error(errorData.message || 'Subscription activation failed');
    }

    const data = await response.json();
    

    return data;
  } catch (error) {
    console.error('Error in subscription activation:', error);
    throw error;
  }
};

export const validateSubscriptionResponse = (response) => {
  const requiredFields = ['subscription', 'active', 'plan_type', 'end_date'];
  const missingFields = requiredFields.filter(field => !response[field]);
  
  if (missingFields.length > 0) {
    console.error('Invalid subscription response. Missing fields:', missingFields);
    return false;
  }
  
  
  return true;
};

export const fetchActiveUsers = async () => {
  try {
    const api = createAdminAPIInstance();
    const response = await api.get('/api/users/active/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetails = async (userId) => {
  try {
    const adminApi = createAdminAPIInstance();
    const activeUsersResponse = await adminApi.get('/api/users/active/');
    const activeUsers = activeUsersResponse.data.users || [];
    
    const user = activeUsers.find(u => u.id === parseInt(userId));
    if (!user) {
      throw new Error('User not found');
    }

    const profileResponse = await defaultInstance.get(`api/user/profile/${userId}/`);
    
    const userData = {
      ...user,
      ...profileResponse.data,
      name: user.name || profileResponse.data.name || `${user.first_name || ''} ${user.last_name || ''}`.trim()
    };

    try {
      const subscriptionResponse = await defaultInstance.get('api/user/subscription/');
      userData.subscription = subscriptionResponse.data;
    } catch (err) {
      userData.subscription = null;
    }

    return userData;

  } catch (error) {
    if (error.message === 'User not found' || error.response?.status === 404) {
      throw new Error('User not found');
    }
    throw error;
  }
};

export const fetchBillingInfo = async () => {
  try {
    const response = await defaultInstance.get('api/user/billing/');
    return response.data;
  } catch (error) {
    console.error('Error fetching billing info:', error);
    throw error;
  }
};

export const updateBillingInfo = async (data) => {
  try {
    const response = await defaultInstance.put('api/user/billing/', data);
    return response.data;
  } catch (error) {
    console.error('Error updating billing info:', error);
    throw error;
  }
};

export const deleteProperty = async (id, userProfile) => {
  const api = createAPIInstance(userProfile);

  try {
    const response = await api.delete(`/api/properties/${id}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProperty = async (id, formData, userProfile) => {
  const api = createAPIInstance(userProfile);

  try {
    const response = await api.put(`/api/properties/${id}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const property_search = async (query, userProfile) => {
  const api = createAPIInstance(userProfile);
  try {
    const searchUrl = `api/property-search/?q=${encodeURIComponent(query)}`;
    const response = await api.get(searchUrl);
    
    let properties = [];
    if (response.data) {
      if (Array.isArray(response.data)) {
        properties = response.data;
      } else if (response.data.properties) {
        properties = response.data.properties;
      }
    }
    
    return properties || [];
  } catch (error) {
    console.error('Error searching properties:', error);
    return [];
  }
};

export const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  try {
    const response = await defaultInstance.post('api/user/update-password/', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'An error occurred while changing the password.');
    } else {
      throw new Error('An unexpected error occurred.');
    }
  }
};

// Folder operations
export const getFolders = async () => {
  try {
    const response = await defaultInstance.get('/api/folders/');
    return response.data;
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const createFolder = async (name, description = '') => {
  try {
    const response = await defaultInstance.post('/api/folders/', {
      name: name.trim(),
      description: description?.trim() || ''
    });
    return response.data;
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const getFolderDetails = async (folderId) => {
  try {
    const response = await defaultInstance.get(`/api/folders/${folderId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching folder details:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const deleteFolder = async (folderId) => {
  try {
    await defaultInstance.delete(`/api/folders/${folderId}/`);
    return true;
  } catch (error) {
    console.error('Error deleting folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const getFolderProperties = async (folderId) => {
  try {
    const response = await defaultInstance.get(`/api/folders/${folderId}/properties/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching folder properties:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const movePropertyBetweenFolders = async (propertyId, sourceFolderId, targetFolderId) => {
  try {
    const response = await defaultInstance.post(`/api/properties/${propertyId}/move/`, {
      source_folder_id: sourceFolderId,
      target_folder_id: targetFolderId
    });
    return response.data;
  } catch (error) {
    console.error('Error moving property between folders:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const savePropertyToFolder = async (propertyId, { folder_id, notes = '' }) => {
  try {
    const data = {
      folder_id: Number(folder_id),
      notes: notes?.trim() || ''
    };

    const response = await defaultInstance.post(
      `/api/properties/${propertyId}/save-to-folder/`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error saving property to folder:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const removePropertyFromFolder = async (folderId, propertyId) => {
  try {
    await defaultInstance.delete(`/api/folders/${folderId}/properties/${propertyId}/`);
    return true;
  } catch (error) {
    console.error('Error removing property from folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const getFoldersContainingProperty = async (propertyId) => {
  try {
    const response = await defaultInstance.get(`api/properties/${propertyId}/saved-folders/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property folders:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const updateFolder = async (folderId, { name, description }) => {
  try {
    const response = await defaultInstance.put(`/api/folders/${folderId}/`, {
      name: name.trim(),
      description: description?.trim() || ''
    });
    return response.data;
  } catch (error) {
    console.error('Error updating folder:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const updatePropertyNotesInFolder = async (folderId, propertyId, notes) => {
  try {
    const response = await defaultInstance.put(
      `/api/folders/${folderId}/properties/${propertyId}/notes/`,
      { notes: notes?.trim() || '' }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating property notes:', error);
    throw error?.response?.data?.error || error.message;
  }
};

export const submitContactForm = async (name, email, message) => {
  try {
    const response = await defaultInstance.post('/api/contact/', {
      name,
      email,
      message,
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || 'An error occurred while submitting the contact form.');
    } else {
      throw new Error('An unexpected error occurred.');
    }
  }
};

export {
  defaultInstance,
  createAPIInstance,
};

export const logout = () => {
  localStorage.removeItem('token');
};