import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCheck, FaTimes } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const scaleIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.success ? '#4CAF50' : '#F44336'};
  color: white;
  animation: ${fadeIn} 0.5s ease-out;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;


const IconWrapper = styled.div`
  font-size: 64px;
  margin-bottom: 20px;
  animation: ${scaleIn} 0.5s ease-out;
  color: white;
  
  svg {
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  }
`;

const Message = styled.h2`
  font-size: 24px;
  text-align: center;
  margin: 0;
  text-shadow: 0 1px 2px rgba(0,0,0,0.1);
  color: white;
`;

const SubscriptionResult = ({ success, message, onClose }) => {
  

  useEffect(() => {
   
    const timer = setTimeout(() => {
     
      onClose();
    }, 5000);

    return () => {
     
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div style={{ 
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: success ? '#4CAF50' : '#F44336',
      padding: '2rem',
      borderRadius: '8px',
      zIndex: 2000,
      minWidth: '300px',
      textAlign: 'center',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }}>
      <IconWrapper>
        {success ? <FaCheck size={48} /> : <FaTimes size={48} />}
      </IconWrapper>
      <Message>{message}</Message>
    </div>
  );
};


export default SubscriptionResult;