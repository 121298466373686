import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppLogo from './SpyLogo.png';

export default function StartPage({ userProfile, onSignInClick }) {
  return (
    <div className="start-page">
      <main className="start-main">
        <div className="start-hero">
          <div className="start-icon">
          <img src={AppLogo} alt="Agent Spyglass Logo" />
          </div>
          
          <h1 className="start-title">EMPOWERING AGENTS WITH TOOLS FOR SUCCESS</h1>
          
          <p className="start-description">Access Compensation data, Upload and View Information not listed on the MLS, all in one convenient place.</p>
          
          <div className="start-buttons">
          <Link to="/properties" className="start-button primary">
              View Properties
            </Link>
            <Link to="/about" className="start-button secondary">
              Learn More
            </Link>
          </div>
        </div>
      </main>

      <footer className="start-footer">
        <div className="footer-nav">
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
        </div>
        <p className="copyright">© {new Date().getFullYear()} Agent Spyglass. All rights reserved.</p>
      </footer>
    </div>
  );
}