import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';

const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const CardContainer = styled.div`
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  background: white;
  margin-bottom: 1.5rem;
`;

const PayButton = styled.button`
  width: 100%;
  padding: 0.75rem 1.5rem;
  background-color: var(--color-blue-main);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: #1a365d;
  }

  &:disabled {
    background-color: #cbd5e0;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  background-color: #fff5f5;
  color: #c53030;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  border: 1px solid #feb2b2;
`;


const BACKEND_URL = process.env.REACT_APP_API_URL;

const CheckoutForm = ({ onPaymentSuccess, amount, planType, userProfile }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      setError('Payment system is not ready. Please try again.');
      return;
    }
  
    if (!userProfile) {
      setError('User profile information is missing. Please try again.');
      return;
    }
  
    setProcessing(true);
    setError(null);
  
    try {
     
      const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
  
if (cardError) {
  console.error('Card error:', cardError);
  const cleanErrorMessage = cardError.message.replace(/Request \w+: /, '');
  throw new Error(`Failed to make payment: ${cleanErrorMessage}`);
}


await onPaymentSuccess(paymentMethod.id);

  
    } catch (err) {
      console.error('Payment error:', err);
      // If the error message contains "Request", clean it up
      const errorMessage = err.message.includes('Request') 
        ? `Failed to make payment: ${err.message.split(': ')[2]}`
        : err.message || 'Failed to make payment: An error occurred';
      setError(errorMessage);
    } finally {
      setProcessing(false);
    }
  };
  
  

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <CardContainer>
          <CardElement 
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
              hidePostalCode: true,
            }}
          />
        </CardContainer>

        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}

        <PayButton 
          type="submit"
          disabled={processing || !stripe}
        >
          {processing 
            ? 'Processing Payment...' 
            : `Pay $${amount} for ${planType} subscription`}
        </PayButton>
      </form>
    </FormContainer>
  );
};

export default CheckoutForm;