import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeCover from './components/HomeCover';
import Feed from './components/Feed';
import Contact from './components/Contact';
import Profile from './components/Profile';
import Home from './components/Home';
import EmailVerificationPage from './components/EmailVerification';
import PasswordResetPage from './components/PasswordResetPage';
import { fetchUserProfile } from './utils/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/Layout';
import { SavedPropertiesProvider } from './components/hooks/usePropertySavedState';
import PropertyDetails from './components/PropertyDetails';
import StartPage from './components/StartPage';
import { useNavigate } from 'react-router-dom';

function AppContent({ userProfile, setUserProfile, updateUserProfile, searchResults, handleSearch, handleResetSearch, handleUserProfileUpdate }) {
  const [hasInitiallyRedirected, setHasInitiallyRedirected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfile && !hasInitiallyRedirected) {
      setHasInitiallyRedirected(true);
      if (window.location.pathname === '/') {
        navigate('/properties');
      }
    }
  }, [userProfile, hasInitiallyRedirected, navigate]);

  return (
    <Layout
      userProfile={userProfile}
      setUserProfile={setUserProfile}
      updateUserProfile={updateUserProfile}
    >
      <Routes>
        <Route path="/" element={<StartPage userProfile={userProfile} />} />
        <Route path="/about" element={<Home userProfile={userProfile} />} />
        <Route
          path="/Properties"
          element={
            <>
              <HomeCover
                page="home"
                userProfile={userProfile}
                onSearch={handleSearch}
                onResetSearch={handleResetSearch}
              />
              <Feed
                userProfile={userProfile}
                onUserProfileUpdate={handleUserProfileUpdate}
                searchResults={searchResults}
              />
              <Contact />
            </>
          }
        />
        <Route
          path="/profile"
          element={
            <Profile
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              updateUserProfile={updateUserProfile}
            />
          }
        />
        <Route path="/verify-email/:uidb64/:token" element={<EmailVerificationPage />} />
        <Route path="/reset-password/:uidb64/:token" element={<PasswordResetPage />} />
        <Route 
          path="/property/:id" 
          element={<PropertyDetails />}
        />
      </Routes>
    </Layout>
  );
}

function App() {
  const [userProfile, setUserProfile] = useState(null);
  const [searchResults, setSearchResults] = useState(null);

  const updateUserProfile = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const profile = await fetchUserProfile();
        setUserProfile(profile);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        localStorage.removeItem('token');
        setUserProfile(null);
      }
    } else {
      setUserProfile(null);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      updateUserProfile();
    }
  }, [updateUserProfile]);

  const handleUserProfileUpdate = useCallback((updatedProfile) => {
    setUserProfile(updatedProfile);
  }, []);
  
  const handleSearch = useCallback((results) => {
    setSearchResults(results);
  }, []);

  const handleResetSearch = useCallback(() => {
    setSearchResults(null);
  }, []);

  return (
    <SavedPropertiesProvider>
      <Router>
        <AppContent
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          updateUserProfile={updateUserProfile}
          searchResults={searchResults}
          handleSearch={handleSearch}
          handleResetSearch={handleResetSearch}
          handleUserProfileUpdate={handleUserProfileUpdate}
        />
        <ToastContainer />
      </Router>
    </SavedPropertiesProvider>
  );
}

export default App;